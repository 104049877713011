import { useState, useEffect } from 'react';
import LocationsService from '../../../services/LocationsService';
import notify from '../../../utils/notifier';
import { Grid, MenuItem, Select, FormControl, InputLabel, Checkbox, FormControlLabel, Typography} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';


export const columns = [
  { id: 'id', label: 'Id',width: '10%' },
  { id: 'placeName', label: 'Place Name',width: '10%', sortable: true },
  { id: 'unlocode', label: 'Unlocode',width: '10%'},
  { id: 'zipCode', label: 'Zip Code',width: '10%', sortable: false  },
  { id: 'city', label: 'City' ,width: '10%', sortable: true },
  { id: 'state', label: 'State',width: '10%', sortable: true },
  { id: 'country', label: 'Country',width: '10%', sortable: true },
  { id: 'locode', label: 'Locode' ,width: '10%'},
  { id: 'locality', label: 'Locality',width: '10%'},
  { id: 'type', label: 'Type',width: '10%' },
  { id: 'latitude', label: 'Latitude',width: '10%' },
  { id: 'longitude', label: 'Longitude' ,width: '10%'},
  { id: 'isActive', label: 'Active',width: '10%' },
  { id: 'isVerified', label: 'Verified',width: '10%' },
  { id: 'actions', label: 'Actions',width: '10%' }
];



export const manageColumns = {
  id: true,
  placeName: true,
  unlocode:true,
  zipCode: true,
  city: true,
  state: true,
  country: true,
  locode: false,
  locality: false,
  type: false,
  latitude: false,
  longitude: false,
  isActive: false,
  isVerified: false,
};

export const Locations = () => {
  const defaultApplicationNamespace = localStorage.getItem('_defaultApplicationNamespace')
  const defaultFilters = {
    city: '',
    state: '',
    country: '',
    state_code: '',
    country_code: '',
    is_active: 'true',
    is_verified: 'true',
    type:[],
    application_namespace: defaultApplicationNamespace
  };

  const applicationNamespaces = JSON.parse(localStorage.getItem('_applicationNamespaces'));
  const applicationNamespaceLocationTypes = JSON.parse(localStorage.getItem('_locationTypes'));
  const [locationsFilterValues, setLocationsFilterValues] = useState(defaultFilters);
  const [backdrop, setBackdrop] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [stateCodes, setStateCodes] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [locationTypes, setLocationTypes] = useState(applicationNamespaceLocationTypes[defaultApplicationNamespace])
  // Fetch countries data on mount
  useEffect(() => {
    if (!countries.length) {
      getCountriesData();
    }
  }, [countries]);

  // Fetch states and cities when the country filter changes
  useEffect(() => {
    if (locationsFilterValues.country) {
      getStatesData(locationsFilterValues.country);
      getCitiesData(locationsFilterValues.country);
    }
  }, [locationsFilterValues.country, countries]);

  // Fetch cities when the state filter changes
  useEffect(() => {
    if (locationsFilterValues.state) {
      getCitiesData(locationsFilterValues.country, locationsFilterValues.state);
    }
  }, [locationsFilterValues.state, states]);

  const defaultFiltersForQuery = {
    city: '',
    state: '',
    country: '',
    state_code: '',
    country_code: '',
    type:[],
    is_active: locationsFilterValues.is_active || 'true',
    is_verified: locationsFilterValues.is_verified || 'true',
    application_namespace: locationsFilterValues.application_namespace || defaultApplicationNamespace,
  };

  // Function to clear the filters
  const handleLocationsFilterClear = (forQuerySearch=false) => {
    setStates([]);
    setCities([]);
    setStateCodes([]);
    setLocationTypes(applicationNamespaceLocationTypes[defaultApplicationNamespace]);
    if(forQuerySearch){
      setLocationsFilterValues(defaultFiltersForQuery);
    }else{
      setLocationsFilterValues(defaultFilters);
    }
  };

  // Function to apply the filters
  const getLocationFilters = (filters) => {
    if (locationsFilterValues.city) {
      const city = cities.find(
        city =>
          city.name === locationsFilterValues.city &&
          city.countryIso2 === locationsFilterValues.country_code
      );
      filters['_cityId'] = city.id;
      delete filters._countryId;
      delete filters._stateId;
    } else if (locationsFilterValues.state) {
      const state = states.find(
        state =>
          state.name === locationsFilterValues.state &&
          state.countryIso2 === locationsFilterValues.country_code
      );
      filters['_stateId'] = state.id;
      delete filters._countryId;
    } else if (locationsFilterValues.country) {
      const country = countries.find(
        country =>
          country.name === locationsFilterValues.country &&
          country.iso2 === locationsFilterValues.country_code
      );
      filters['_countryId'] = country.id;
    } 
    
    if (locationsFilterValues.type.length) {
      filters['_type'] = Array.isArray(locationsFilterValues.type)
      ? locationsFilterValues.type.join('|')
      : locationsFilterValues.type;
    } 

    if(locationsFilterValues.application_namespace){
      filters['_applicationNamespace'] = locationsFilterValues.application_namespace;
    }

    filters['_isActive'] = locationsFilterValues.is_active;
    filters['_isVerified'] = locationsFilterValues.is_verified;
    return filters;
  };

  // Function to fetch countries
  const getCountriesData = async () => {
    try {
      setBackdrop(true);
      const response = await LocationsService.getCountriesData();
      const countriesData = response.data.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
      const countryCodesData = countriesData.map(x => x.iso2).sort();
      setCountries(countriesData);
      setCountryCodes(countryCodesData);
    } catch (error) {
      notify({ type: 'error', message: 'Error fetching countries data' });
    } finally {
      setBackdrop(false);
    }
  };

  // Function to fetch states based on country
  const getStatesData = async countryName => {
    try {
      const country = countries.find(country => country.name === countryName);
      const query = { _countryIso2: country.iso2 };
      setBackdrop(true);
      const response = await LocationsService.getStatesData(query);
      const statesData = response.data.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });;
    
      setStates(statesData);
      setStateCodes(statesData.map(x => x.stateIso2));
    } catch (error) {
      notify({ type: 'error', message: 'Error fetching states data' });
    } finally {
      setBackdrop(false);
    }
  };

  // Function to fetch cities based on country and state
  const getCitiesData = async (countryName, stateName = '') => {
    try {
      const country = countries.find(country => country.name === countryName);
      const query = { _countryIso2: country.iso2 };
      if (stateName) {
        const state = states.find(state => state.name === stateName);
        query['_stateIso2'] = state.stateIso2;
      }
      setBackdrop(true);
      const response = await LocationsService.getCitiesData(query);
      response.data.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });;
      setCities(response.data);
    } catch (error) {
      notify({ type: 'error', message: 'Error fetching cities data' });
    } finally {
      setBackdrop(false);
    }
  };

  const handleCountryFilterChange = event => {
    const { name, value } = event.target;
    const country = countries.find(country => country.name === value);
    setLocationsFilterValues(prevValues => ({
      ...prevValues,
      [name]: value,
      country_code: country.iso2
    }));
    getStatesData(value);
    getCitiesData(value);
  };

  const handleStateFilterChange = event => {
    const { name, value } = event.target;
    const state = states.find(state => state.name === value);
    setLocationsFilterValues(prevValues => ({
      ...prevValues,
      [name]: value,
      state_code: state.stateIso2 || ''
    }));
    if (!locationsFilterValues.city) {
      getCitiesData(locationsFilterValues.country, value);
    }
  };

  const handleCityFilterChange = event => {
    const { name, value } = event.target;
    if (!locationsFilterValues.state) {
      const selectedCity = cities.find(city => city.name === value);
      setLocationsFilterValues(prevValues => ({
        ...prevValues,
        [name]: value,
        state: selectedCity ? selectedCity.stateName : prevValues.state,
        state_code: selectedCity ? selectedCity.stateIso2 : prevValues.state_code,
        country: selectedCity ? selectedCity.countryName : prevValues.country,
        country_code: selectedCity ? selectedCity.countryIso2 : prevValues.country_code
      }));
    } else {
      setLocationsFilterValues(prevValues => ({
        ...prevValues,
        [name]: value
      }));
    }
  };

  const handleCountryCodeFilterChange = event => {
    const { name, value } = event.target;
    const country = countries.find(country => country.iso2 === value);
    setLocationsFilterValues(prevValues => ({
      ...prevValues,
      [name]: value,
      country: country.name || ''
    }));
    getStatesData(country.name);
    getCitiesData(country.name);
  };

  const handleApplicationNamespaceFilterChange = event => {
    const { name, value } = event.target;
    setLocationsFilterValues(prevValues => ({
      ...prevValues,
      [name]: value,
    }));
    setLocationTypes(applicationNamespaceLocationTypes[value])
  };

  const handleStateCodeFilterChange = event => {
    const { name, value } = event.target;
    const state = states.find(state => state.stateIso2 === value);
    setLocationsFilterValues(prevValues => ({
      ...prevValues,
      [name]: value,
      state: state.name || ''
    }));
    getCitiesData(locationsFilterValues.country, state.name);
  };

  const handleTypeFilterChange = event => {
    const { name, value } = event.target;
    setLocationsFilterValues(prevValues => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleActiveFilterChange = event => {
    const { name, value } = event.target;
    const parsedValue = value === 'true' ? 'true' : 'false';

    setLocationsFilterValues(prevValues => ({
      ...prevValues,
      [name]: parsedValue
    }));
  };

  const handleVerifiedFilterChange = event => {
    const { name, value } = event.target;
    const parsedValue = value === 'true' ? 'true' : 'false';

    setLocationsFilterValues(prevValues => ({
      ...prevValues,
      [name]: parsedValue
    }));
  };

  const downloadLocationsData = async query => {
    try {
      if(query._isActive === undefined){
        query._isActive = true;
      }
      if(query._isVerified === undefined){
        query._isVerified = true;
      }
      if(query._format === undefined || !query._format){
        query._format = 'CSV'
      }
      const response = await LocationsService.downloadLocationsData(query);;

      const link = document.createElement('a');
      link.href = URL.createObjectURL(response.data);
      
      const timestamp = new Date().toLocaleString('en-GB').replace(/[/,: ]/g, '-');
      const fileName = `locations_${timestamp}.csv`;
      
      link.download = fileName; 
      link.click();
      setTimeout(() => {
        notify({
          type: 'success',
          message: 'File downloaded successfully.'
        });
      }, 50); 
    } catch (error) {
      console.error('Failed to download file:', error);
      notify({
        type: 'error',
        message: 'Something went wrong while downloading the file.'
      });
    }
  };
  // Function to fetch location data
  const fetchLocationsData = async query => {
    let output = {};
    try {
      if(query._isActive === undefined){
        query._isActive = true;
      }
      if(query._isVerified === undefined){
        query._isVerified = true;
      }
      const response = await LocationsService.getLocationsData(query);
      output.data = []
      response.data.forEach(response => {
        output.data.push({
          id : response.id,
          placeName: response.placeName,
          locality: response.locality,
          zipCode: response.zipcode,
          state: response.stateDetails.name,
          latitude: response.lat,
          longitude: response.long,
          isActive: response.isActive,
          isVerified: response.verified,
          type: response.type,
          locode: response.locode,
          city: response.cityDetails.name,
          country: response.countryDetails.name,
          createdAt: response.createdAt,
          updatedAt: response.updatedAt,
          comment: response.comment,
          unlocode: response.unlocode
        })
      })
      if (response.headers.hasOwnProperty('x-total-count')) {
        output['x-total-count'] = parseInt(response.headers['x-total-count']);
      }
    } catch (error) {
      notify({
        type: 'error',
        message: 'Internal Server Error'
      });
    }
    return output;
  };

  const locationsFilterConfig = [
    {
      name: 'city',
      label: 'City',
      isAutocomplete: true,
      type: 'select',
      options: cities,
      filterChange: handleCityFilterChange
    },
    {
      name: 'state',
      label: 'State',
      isAutocomplete: true,
      type: 'select',
      options: states,
      filterChange: handleStateFilterChange
    },
    {
      name: 'country',
      label: 'Country',
      type: 'select',
      isAutocomplete: true,
      options: countries,
      filterChange: handleCountryFilterChange
    },
    {
      name: 'state_code',
      label: 'State Code',
      isAutocomplete: true,
      type: 'select',
      options: stateCodes,
      filterChange: handleStateCodeFilterChange
    },
    {
      name: 'country_code',
      label: 'Country Code',
      isAutocomplete: true,
      type: 'select',
      options: countryCodes,
      filterChange: handleCountryCodeFilterChange
    },
    {
      name: 'type',
      label: 'Type',
      type: 'select',
      options: locationTypes,
      filterChange: handleTypeFilterChange
    },
    {
      name: 'is_active',
      label: 'Active',
      type: 'select',
      options: [
        { value: 'true', label: 'true' },
        { value: 'false', label: 'false' }
      ],
      filterChange: handleActiveFilterChange
    },
    {
      name: 'is_verified',
      label: 'Verified',
      type: 'select',
      options: [
        { value: 'true', label: 'true' },
        { value: 'false', label: 'false' }
      ],
      filterChange: handleVerifiedFilterChange
    },
    {
      name: 'application_namespace',
      label: 'Application Namespace',
      type: 'select',
      options: applicationNamespaces,
      filterChange: handleApplicationNamespaceFilterChange
    }
  ];

  const renderLocationsFilters = () => {
    return locationsFilterConfig.map(filter => {
      const options = filter.options;
      const isCountrySelected =
        !!locationsFilterValues.country_code || !!locationsFilterValues.country;
      const isStateSelected =
        !!locationsFilterValues.state_code || !!locationsFilterValues.state;

      // Determine if the current filter should be disabled
      const isDisabled =
        ((filter.name === 'state' || filter.name === 'state_code' || filter.name === 'city') && !isCountrySelected) || (filter.name === 'city' &&
        !isStateSelected) || (filter.name === 'type' && !locationsFilterValues.application_namespace);
        
      const isTypeFilter = filter.name === 'type'; // Check if it's the 'type' filter
  
      return (
        <Grid item xs={4} key={filter.name}>
          <FormControl fullWidth>
            <InputLabel>{filter.isAutocomplete ? '' : filter.label}</InputLabel>
            {filter.isAutocomplete ? (
              <Autocomplete
                multiple={isTypeFilter} // Enable multiple selection for type filter
                options={options.map(option => option.value || option.name || option)}
                value={locationsFilterValues[filter.name] || []}
                disabled={isDisabled}
                onChange={(event, newValue) => {
                  // Update the state for autocomplete
                  filter.filterChange({
                    target: {
                      name: filter.name,
                      value: newValue,
                    },
                  });
                }}
                disableClearable
                renderInput={(params) => (
                  <TextField 
                    {...params} 
                    label={filter.label} 
                    variant="standard" // Use standard variant for underline style
                  />
                )}
              
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {options.find(o => o.value === option)?.label || option}
                  </li>
                )}
              />
            ) : (
              <Select
                name={filter.name}
                value={locationsFilterValues[filter.name]}
                onChange={isTypeFilter ? null : filter.filterChange}
                disabled={isDisabled}
                multiple={isTypeFilter} // Enable multiple selection for type filter
                renderValue={(selected) =>
                  Array.isArray(selected) ? selected.join(', ') : selected
                }
              >
                {options.map(option => (
                  <MenuItem
                    key={option.value || option.name || option}
                    value={option.value || option.name || option}
                  >
                    {isTypeFilter ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={(locationsFilterValues[filter.name] || []).includes(option.value || option.name || option)}
                            onChange={(event) => {
                              const currentValues = locationsFilterValues[filter.name] || [];
                              const newValue = event.target.value;

                              // Add or remove the value from the list
                              const updatedValues = event.target.checked
                                ? [...currentValues, newValue]
                                : currentValues.filter(value => value !== newValue);

                              // Update the state
                              filter.filterChange({
                                target: {
                                  name: filter.name,
                                  value: updatedValues,
                                },
                              });
                            }}
                            value={option.value || option.name || option}
                          />
                        }
                        label={option.label || option.name || option}
                      />
                    ) : (
                      option.label || option.name || option
                    )}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </Grid>
      );
    });
  };

  const RenderLocationsFiltersPreview = ({ mainFilters }) => {
    let filters = {}

    if(locationsFilterValues.application_namespace){
      filters['Application Namespace'] = locationsFilterValues.application_namespace
    }
    if(locationsFilterValues.type != ''){
      filters.Type = locationsFilterValues.type
    }
    if(locationsFilterValues.is_active){
      filters['Active']  = locationsFilterValues.is_active
    }
    if(locationsFilterValues.is_verified){
      filters['Verified']  = locationsFilterValues.is_verified
    }
    if(locationsFilterValues.country){
      filters.Country = locationsFilterValues.country
    }
    if(locationsFilterValues.state){
      filters.State = locationsFilterValues.state
    }
    if(locationsFilterValues.city){
      filters.City = locationsFilterValues.city
    }

    return (<div
      style={{
        marginBottom: '10px',
        padding: '5px',
        backgroundColor: '#f9f9f9',
        border: '1px solid #ddd',
        borderRadius: '4px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      <Typography
        variant="subtitle2"
        style={{
          display: 'inline-block',
          fontWeight: 600,
          marginRight: '10px',
          marginLeft: '10px'
        }}
      >
        Filters :
      </Typography>
      <span style={{ display: 'flexible' }}>
        {Object.entries(filters).map(([key, value], index) => (
          <span
            key={key}
            style={{
              fontSize: '13px',
              padding: '3px 10px',
              backgroundColor: '#f9f9f9',
              marginRight: index !== Object.entries(filters).length - 1 ? '5px' : '0',
            }}
          >
            {`${key} : ${value}`}
          </span>
        ))}
      </span>
    </div>)
  };

  return {
    locationsFilterValues,
    setLocationsFilterValues,
    handleLocationsFilterClear,
    getLocationFilters,
    getCountriesData,
    getStatesData,
    getCitiesData,
    fetchLocationsData,
    downloadLocationsData,
    backdrop,
    setBackdrop,
    locationsFilterConfig,
    renderLocationsFilters,
    RenderLocationsFiltersPreview
  };
};
