import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tabs, Tab, Paper, Button, Grid, Menu, MenuItem, Typography } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import notify from '../../utils/notifier';
import { manageColumns as locationsManageColumns } from './Locations/Locations';
import { columns as locationsColumns } from './Locations/Locations';
import { Locations } from './Locations/Locations';
import { manageColumns as zonesManageColumns } from './Zones/Zones';
import { columns as zonesColumns } from './Zones/Zones';
import { Zones } from './Zones/Zones';
import { Addresses, addressManageColumns, addressColumns } from './Addresses/Addresses';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from '../../styles/ManageLocationsStyles';
import PaginationComponent from '../PaginationComponent';
import ManageColumnsDialog from '../ManageColumnsDialog';
import SearchBar from '../SearchBarComponent';
import TableComponent from '../TableComponent';
import FilterDialog from '../FilterDialog';

const MAXIMUM_CSV_DOWNLOAD_SIZE = 10000;
const tabMapping = {
  zones: 0,
  locations: 1,
  addresses: 2
};

const reverseTabMapping = Object.keys(tabMapping).reduce(
  (obj, key) => ({ ...obj, [tabMapping[key]]: key }),
  {}
);

const _columns = {
  locations: locationsColumns,
  addresses: addressColumns,
  zones: zonesColumns
};

const _manageColumns = {
  locations: locationsManageColumns,
  addresses: addressManageColumns,
  zones: zonesManageColumns
};

const ManageLocations = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(1);
  const [page, setPage] = useState(0);
  const [rows, setRows] = React.useState([]);
  const [filters, setFilters] = useState({});
  const [originalRows, setOriginalRows] = React.useState([]);
  const [originalQueryCount, setOriginalQueryCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [circularBackdrop, setCircularBackdrop] = useState(false);
  const [openManageColumnsDialog, setOpenManageColumnsDialog] = useState(false);
  const [queryCount, setQueryCount] = useState(0);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [columns, setColumns] = React.useState(_columns['locations']);
  const [manageColumns, setManageColumns] = useState(_manageColumns['locations']);
  const [searchKey, setSearchKey] = useState({ value: '' });

  const {
    fetchZonesData,
    downloadZonesData,
    renderZonesFilters,
    getZoneFilters,
    RenderZonesFiltersPreview,
    handleZonesFilterClear
  } = Zones();

  const {
    fetchLocationsData,
    downloadLocationsData,
    getLocationFilters,
    renderLocationsFilters,
    RenderLocationsFiltersPreview,
    handleLocationsFilterClear
  } = Locations();

  const {
    fetchAddressesData,
    downloadAddressesData,
    renderAddressesFilters,
    getAddressFilters,
    RenderAddressesFiltersPreview,
    handleAddressesFilterClear
  } = Addresses();

  const _fetchData = {
    locations: fetchLocationsData,
    addresses: fetchAddressesData,
    zones: fetchZonesData
  };

  const _downloadData = {
    locations: downloadLocationsData,
    addresses: downloadAddressesData,
    zones: downloadZonesData
  };

  const _applyFilter = {
    locations: getLocationFilters,
    addresses: getAddressFilters,
    zones: getZoneFilters
  };

  const _renderFilters = {
    locations: () => <>{renderLocationsFilters()}</>,
    addresses: () => <>{renderAddressesFilters()}</>,
    zones: () => <>{renderZonesFilters()}</>,
  };

  const _clearFilter = {
    locations: handleLocationsFilterClear,
    addresses: handleAddressesFilterClear,
    zones: handleZonesFilterClear
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get('tab'); // Get the tab query parameter
    if (tab && tabMapping[tab] !== undefined) {
      setSelectedTab(tabMapping[tab]); // Set the selected tab based on the query parameter
      setColumns(_columns[tab])
      setManageColumns(_manageColumns[tab])
      setSearchKey({ value: '' });
      setOrder('asc');
      setOrderBy('');
      setPage(0);
      let curFilters = _applyFilter[reverseTabMapping[tabMapping[tab]]]({})
      setFilters(curFilters)
      fetchData(tabMapping[tab], rowsPerPage, 0, '', 'asc', '', curFilters, true);
    }
  }, [location.search]);

  const handleSearch = event => {
    const _val = event.target.value;
    if(_val.length <= 2 && searchKey.value.length <= 2){
      setSearchKey(prevState => {
        return { ...prevState, value: _val };
      });
      return;
    }
    // let curFilters = {}
    // if(filters._applicationNamespace){
    //   curFilters._applicationNamespace = filters._applicationNamespace
    // }
    // if(filters.applicationNamespace){
    //   curFilters.applicationNamespace = filters.applicationNamespace
    // }
    // if(filters._isActive){
    //   curFilters._isActive = filters._isActive
    // }
    // if(filters._isVerified){
    //   curFilters._isVerified = filters._isVerified
    // }
    // setFilters(filters);
    // _clearFilter[reverseTabMapping[selectedTab]](true);
    setSearchKey(prevState => {
      return { ...prevState, value: _val };
    });
    if (_val.length > 2) {
      setPage(0);
      fetchData(selectedTab, rowsPerPage, 0 , orderBy, order, _val, filters);
    }else {
      setRows(originalRows);
      setQueryCount(originalQueryCount);
      setRowsPerPage(rowsPerPage);
      setPage(0);
    }
  };

  const handleNavigation = tab => {
    const tabName = reverseTabMapping[tab];
    navigate(`/locations?tab=${tabName}`); // Pass the selected tab as a query parameter
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setColumns(_columns[reverseTabMapping[newValue]]);
    setManageColumns(_manageColumns[reverseTabMapping[newValue]]);
    setFilters({});
    setSearchKey({ value: '' });
    setOrder('asc');
    setOrderBy('');
    setPage(0);
    handleNavigation(newValue);
  };

  const handleRequestSort = property => event => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
    fetchData(selectedTab, rowsPerPage, page, property, isDesc ? "asc" : "desc", searchKey.value, filters);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchData(selectedTab, rowsPerPage, newPage, orderBy, order, searchKey.value, filters);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    fetchData(selectedTab, +event.target.value, 0, orderBy, order, searchKey.value, filters, true);
  };

  const handleFilterButtonClick = () => {
    setOpenFilterDialog(true); // Open filter modal when the filter button is clicked
  };

  const handleFilterClose = () => {
    setOpenFilterDialog(false);
  };

  // Handle Apply filter
  const handleApplyFilters = () => {
    setPage(0);
    setSearchKey({ value: '' });
    setOpenFilterDialog(false);
    setCircularBackdrop(true);
    fetchData(selectedTab, rowsPerPage, 0, orderBy, order, '', _applyFilter[reverseTabMapping[selectedTab]](filters), true);

  };

  const handleClearFilters = () => {
    setFilters({});
    _clearFilter[reverseTabMapping[selectedTab]]();
  };

  const handleToggleColumn = column => event => {
    setManageColumns({
      ...manageColumns,
      [column]: event.target.checked
    });
  };

  const downloadData = () => {
    if(queryCount > MAXIMUM_CSV_DOWNLOAD_SIZE) {
      notify({
        type: 'info',
        message: `Maximum ${MAXIMUM_CSV_DOWNLOAD_SIZE} rows are supported to download.`
      });
    }else{
      fetchData(selectedTab, MAXIMUM_CSV_DOWNLOAD_SIZE, 0, orderBy, order, searchKey.value, filters, false, true);
    }
  }; 

  const handleManageColumnsOpen = () => {
    setOpenManageColumnsDialog(true);
  };

  const handleManageColumnsClose = () => {
    setOpenManageColumnsDialog(false);
  };

  const clearSearchText = () => {
    setSearchKey({ value: '' });
    setPage(0);
    fetchData(selectedTab, rowsPerPage, 0, orderBy, order, '', filters);
  };

  const handleMenuClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentRow(null);
  };

  const handleActionClick = action => {
    if (action === 'View') {
      if(selectedTab == 1){
        navigate(`/locations/locations/${currentRow.id}`, { state: { location: currentRow } }); // Navigate to the view page
      }else if(selectedTab == 2){
        navigate(`/locations/addresses/${currentRow.id}`, { state: { address: currentRow } });
      }else if(selectedTab == 0){
        navigate(`/locations/zones/${currentRow.id}`, { state: { zone: currentRow } });
      }
    }
    handleMenuClose();
  };

  const fetchData = async (
    tab,
    pageSize = rowsPerPage,
    currentPage = 0,
    sortBy = orderBy,
    sortOrder = order,
    searchText = null,
    filterParams = filters,
    originalData = false,
    download = false
  ) => {
    setCircularBackdrop(true);
    let query = {
      _page: currentPage + 1,
      _limit: pageSize,
      _order: sortOrder,
      ...filterParams
    };
    if(sortBy){
      query._sort = sortBy;
    }
    if (searchText && searchText.length > 2) {
      query['q'] = encodeURIComponent(searchText);
    }

    if(download){
      await _downloadData[reverseTabMapping[tab]](query)
      setCircularBackdrop(false);
    }else{
      _fetchData[reverseTabMapping[tab]](query)
      .then(response => {
        let _data = response.data;
        setRows(_data);
        if(originalData){
          setOriginalRows(_data);
        }
        if (response.hasOwnProperty('x-total-count')) {
          setQueryCount(parseInt(response['x-total-count']));
          if(originalData){
            setOriginalQueryCount(parseInt(response['x-total-count']));
          }
        }
        setCircularBackdrop(false);
      })
      .catch(error => {
        setCircularBackdrop(false);
        notify({
          type: 'error',
          message: 'Internal Server Error'
        });
        setRows(null);
      });
    }
  };

  return (
    <div style={{
      height: 'calc(100vh - 120px)', // Occupy 80% of the viewport height
      padding: '30px', // Padding for inner spacing
    }}>
      <div style={{marginBottom: '10px'}}>
        <Typography className={classes.headerText} variant="h6" component="h2">
              Manage {reverseTabMapping[selectedTab]}
        </Typography>
      </div>
      <div style={{padding:'20px', backgroundColor: 'white', color:'black'}}>
        <Backdrop open={circularBackdrop} style={{ color: 'primary', zIndex: 1000000 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid item xs={12}>
          <Tabs  value={selectedTab} onChange={handleTabChange} aria-label="location tabs">
            <Tab label="Zones" style={{
                fontWeight: 'bold',
              }}/>
            <Tab label="Locations" style={{
                fontWeight: 'bold',
              }}/>
            <Tab label="Addresses" style={{
                fontWeight: 'bold',
              }}/>
          </Tabs>
        </Grid>

        {/* Filter and Manage Columns */}
        <Grid container justifyContent="space-between" style={{ padding: '10px' }}>
          <div className={classes.tableHeadRight}>
            <SearchBar
              searchKey={searchKey}
              handleSearch={handleSearch}
              clearSearchText={clearSearchText}
              handleFilterButtonClick={handleFilterButtonClick}
            />
            <Button
              variant="outlined"
              style={{ marginLeft: '10px',marginTop: '10px'  }}
              startIcon={<FilterListIcon />}
              onClick={handleFilterButtonClick}
            >
              Filters
            </Button>
            <Button
              variant="outlined"
              style={{ marginLeft: '10px',marginTop: '10px' }}
              onClick={handleManageColumnsOpen}
            >
              Manage Columns
            </Button>
            <Button
              variant="outlined"
              style={{ marginLeft: '10px',marginTop: '10px' }}
              onClick={downloadData}
            >
              Download
            </Button>
          </div>
        </Grid>

        <div>
          {selectedTab === 0 ? (
            <RenderZonesFiltersPreview
              filters={filters}
            />
          ) : selectedTab === 1 ? (
            <RenderLocationsFiltersPreview
              filters={filters}
            />
          ) : (
            <RenderAddressesFiltersPreview
              filters={filters}
            />
          )}
        </div>
      
        {/* Table */}
        <TableComponent
          rows={rows}
          columns={columns}
          manageColumns={manageColumns}
          handleMenuClick={handleMenuClick}
          circularBackdrop={circularBackdrop}
          handleRequestSort={handleRequestSort}
          orderBy={orderBy}
          order={order}
        />

        {/* Pagination */}
        <PaginationComponent
          queryCount={queryCount}
          page={page}
          handleChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />

        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={() => handleActionClick('View')}>View</MenuItem>
          {/* <MenuItem onClick={() => handleActionClick('Edit')}>Edit</MenuItem>
          <MenuItem onClick={() => handleActionClick('Archive')}>Archive</MenuItem> */}
        </Menu>

        <FilterDialog
          open={openFilterDialog}
          handleClose={handleFilterClose}
          handleApplyFilters={handleApplyFilters}
          renderFilters={_renderFilters[reverseTabMapping[selectedTab]]}
          handleFilterClear={handleClearFilters}
        />

        <ManageColumnsDialog
          open={openManageColumnsDialog}
          handleClose={handleManageColumnsClose}
          manageColumns={manageColumns}
          handleToggleColumn={handleToggleColumn}
        />
      </div>
    </div>
  );
};

export default ManageLocations;
